// src/views/Logs.js
import React from 'react';

const Logs = () => {
return (

    <div className="my-new-page">
      <h1>Welcome to My New Page</h1>
    </div>
  );
};

export default Logs;