// src/data/pageData.js
import page1 from './content/一個毫無道德土壤的國家';
import page2 from './content/page-2';
import page3 from './content/page-3';

const pageData = [
	page1,
	page2,
	page3,
];

export default pageData;