// ----------------------------------------------------//
//					dailyWebsites
// ----------------------------------------------------//
const dailyWebsites = [
{
"webimg": "/assets/images/sites/icons8-google-48.png",
"websitename": "Google",
"weburl": "https://www.google.com.hk/"
},
{
"webimg": "/assets/images/sites/youtube_favicon_96x96.png",
"websitename": "Youtube",
"weburl": "https://www.youtube.com/?gl=HK"
},
{
"webimg": "/assets/images/sites/icon-raindrop.png",
"websitename": "Raindrop",
"weburl": "https://app.raindrop.io/"
},
{
"webimg": "/assets/images/sites/icons8-facebook-48.png",
"websitename": "Facebook",
"weburl": "https://www.facebook.com/"
},
{
"webimg": "/assets/images/sites/icons8-gmail-48.png",
"websitename": "Gmail",
"weburl": "https://mail.google.com/mail/u/1/?tab=wm#inbox"
},
{
"webimg": "/assets/images/sites/icon-instagram.png",
"websitename": "Instagram",
"weburl": "https://www.instagram.com/"
},
{
"webimg": "/assets/images/sites/icons8-twitter-96.png",
"websitename": "Twitter",
"weburl": "https://twitter.com/"
},
{
"webimg": "/assets/images/sites/icons8-notion-50.png",
"websitename": "Notion",
"weburl": "https://www.notion.so/"
},
{
"webimg": "/assets/images/sites/premierleague.png",
"websitename": "Premier League",
"weburl": "https://www.premierleague.com/"
},
{
"webimg": "/assets/images/sites/icon-gtranslate-favicon.png",
"websitename": "G. Translate",
"weburl": "https://translate.google.com/?source=gtx"
},
{
"webimg": "/assets/images/sites/Icon-Poe-Favicon.png",
"websitename": "POE",
"weburl": "https://poe.com/"
},
{
"webimg": "/assets/images/sites/vercel-60x60.png",
"websitename": "Vercel",
"weburl": "https://vercel.com/dashboard"
},
{
"webimg": "/assets/images/sites/icons8-dropbox-48.png",
"websitename": "Dropbox",
"weburl": "https://www.dropbox.com/"
},

];

// ----------------------------------------------------//
//					magazineWebsites
// ----------------------------------------------------//
const magazineWebsites = [
{
"webimg": "/assets/images/sites/icons8-netflix-482.png",
"websitename": "Netflix",
"weburl": "https://www.netflix.com/browse"
},
{
"webimg": "/assets/images/sites/icons8-disney-96.png",
"websitename": "Disney+",
"weburl": "https://www.disneyplus.com/zh-hk/home"
},
{
"webimg": "/assets/images/sites/icon-spotify.png",
"websitename": "Spotify",
"weburl": "https://open.spotify.com/browse/featured"
},
{
"webimg": "/assets/images/sites/icons8-pinterest-48.png",
"websitename": "Pinterest",
"weburl": "https://www.pinterest.com/"
},
{
"webimg": "/assets/images/sites/icons8-linkedin-48.png",
"websitename": "Linkedin",
"weburl": "https://www.linkedin.com/feed/"
},
{
"webimg": "/assets/images/sites/icon-whatsapp.png",
"websitename": "Whatsapp",
"weburl": "https://web.whatsapp.com/"
},
];

// ----------------------------------------------------//
//					workWebsites
// ----------------------------------------------------//
const workWebsites = [
{
"webimg": "/assets/images/sites/webimg",
"websitename": "MS Office",
"weburl": "https://www.office.com/"
},
{
"webimg": "/assets/images/sites/webimg",
"websitename": "Jira",
"weburl": "https://elab-aswatson.atlassian.net/secure/Dashboard.jspa"
},
{
"webimg": "/assets/images/sites/webimg",
"websitename": "Confluence",
"weburl": "https://elab-aswatson.atlassian.net/wiki/spaces/AEA/overview"
},
{
"webimg": "/assets/images/sites/webimg",
"websitename": "Topdesk",
"weburl": "http://topdesk.aswatson.net"
},
{
"webimg": "/assets/images/sites/webimg",
"websitename": "Watsup",
"weburl": "https://aswatson.mangoapps.com/ce/pulse/user/overview/index"
},
{
"webimg": "/assets/images/sites/webimg",
"websitename": "Teams",
"weburl": "https://teams.microsoft.com/"
},
{
"webimg": "/assets/images/sites/webimg",
"websitename": "Simplenote",
"weburl": "https://app.simplenote.com/"
},
];

// ----------------------------------------------------//
//					devWebsites
// ----------------------------------------------------//
const devWebsites = [
{
"webimg": "/assets/images/sites/icon-digitalocean.png",
"websitename": "Digital Ocean",
"weburl": "https://cloud.digitalocean.com/"
},
{
"webimg": "/assets/images/sites/icons8-unsplash-96.png",
"websitename": "Unsplash",
"weburl": "https://unsplash.com/"
},
{
"webimg": "/assets/images/sites/icons8-dribbble-96.png",
"websitename": "Dribbble",
"weburl": "https://dribbble.com/"
},
{
"webimg": "/assets/images/sites/icons8-codepen-50.png",
"websitename": "Codepen",
"weburl": "https://codepen.io/"
},
];


// {
//   "webimg": "/assets/images/sites/webimg",
//   "websitename": "websitename",
//   "weburl": "weburl"
// },



export { dailyWebsites, magazineWebsites, workWebsites, devWebsites };